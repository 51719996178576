import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import logoIconWhite from '../img/logo-icon-white.svg'

class thankYou extends React.Component {
  render() {
    const siteTitle = "Thank You"
    const siteDescription = this.props.data.site.siteMetadata.description
    const siteKeywords = this.props.data.site.siteMetadata.keywords;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} keywords={[siteKeywords]}/>
        <div className="container-fluid headline">
          <div className="container">
            <div className="col-md-10 offset-md-1">
              
              <h1 className="text-white mt-0">
                <img src={logoIconWhite} className="logo-icon" alt="Logo" />
                Thank You!
              </h1>
            </div>
          </div>
        </div>
        <div className="container faq-content">
          <div className="row">
          <div className="col-12">
            <h2>Thanks for filling out our form!</h2>
            <p>We have received your message and would like to thank you for writing to us. If your inquiry is urgent, please use the telephone number listed below to talk to one of our staff members. Otherwise, we will reply by email as soon as possible.</p>
            <div className="d-flex justify-content-start mt-4"><Link className="btn-global mr-5 nav-cta" to="/">Return Home</Link></div>
          </div>
          </div>

        </div>
      </Layout>
    )
  }
}

export default thankYou

export const thankYouQuery = graphql`
  query thankYouQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
  `